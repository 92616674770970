import styled from "styled-components";
import { theme } from "src/styles/global";
import { Text } from "src/styles/components";

export const Wrapper = styled.section`
  max-width: 1024px;
  box-sizing: border-box;
  padding: 135px 25px 70px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 70px;

  @media (${theme.device.tablet}) {
    padding: 70px 25px;
    padding-top: 110px;
    gap: 30px;
  }
`;

export const TermsText = styled(Text)`
  font-size: 18px;

  a {
    color: ${theme.colors.graySuit};

    &:hover {
      transition: color 0.3s cubic-bezier(0.14, 0.48, 0, 0.96);
      color: ${theme.colors.white};
    }
  }

  @media (${theme.device.tablet}) {
    font-size: 14px;
  }
`;

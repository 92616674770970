import React, { useEffect } from "react";
import { TermsText, Wrapper } from "src/components/termsAndUse/styled";
import { H1, H2 } from "src/styles/components";
import Layout from "src/components/layout/layout";
import { HelmetComponent } from "components/helmet";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout key="policy">
      <HelmetComponent />
      <Wrapper>
        <H1 transform="uppercase">Privacy Policy</H1>
        <section>
          <TermsText>
            Your privacy is important to us. It is Greyapes.com&#39;s policy to
            respect your privacy and comply with any applicable law and
            regulation regarding any personal information we may collect about
            you, including across our website,
            <a href="https://greyapes.com"> https://greyapes.com</a>, and other
            sites we own and operate.
          </TermsText>
          <TermsText>
            Personal information is any information about you which can be used
            to identify you. This includes information about you as a person
            (such as name, address, and date of birth), your devices, payment
            details, and even information about how you use a website or online
            service.
          </TermsText>
          <TermsText>
            In the event our site contains links to third-party sites and
            services, please be aware that those sites and services have their
            own privacy policies. After following a link to any third-party
            content, you should read their posted privacy policy information
            about how they collect and use personal information. This Privacy
            Policy does not apply to any of your activities after you leave our
            site.
          </TermsText>
          <TermsText>This policy is effective as of 26 April 2022.</TermsText>
          <TermsText>Last updated: 26 April 2022</TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Information We Collect</H2>
          <TermsText>
            Information we collect falls into one of two categories:
            &ldquo;voluntarily provided&rdquo; information and
            &ldquo;automatically collected&rdquo; information.
          </TermsText>
          <TermsText>
            &ldquo;Voluntarily provided&rdquo; information refers to any
            information you knowingly and actively provide us when using or
            participating in any of our services and promotions.
          </TermsText>
          <TermsText>
            &ldquo;Automatically collected&rdquo; information refers to any
            information automatically sent by your devices in the course of
            accessing our products and services.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Log Data</H2>
          <TermsText>
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. It may include your
            device’s Internet Protocol (IP) address, your browser type and
            version, the pages you visit, the time and date of your visit, the
            time spent on each page, and other details about your visit.
          </TermsText>
          <TermsText>
            Additionally, if you encounter certain errors while using the site,
            we may automatically collect data about the error and the
            circumstances surrounding its occurrence. This data may include
            technical details about your device, what you were trying to do when
            the error happened, and other technical information relating to the
            problem. You may or may not receive notice of such errors, even in
            the moment they occur, that they have occurred, or what the nature
            of the error is.
          </TermsText>
          <TermsText>
            Please be aware that while this information may not be personally
            identifying by itself, it may be possible to combine it with other
            data to personally identify individual persons.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Device Data</H2>
          <TermsText>
            When you visit our website or interact with our services, we may
            automatically collect data about your device, such as:
          </TermsText>
          <ul>
            <TermsText as="li">Device Type</TermsText>
            <TermsText as="li">Geo-location data</TermsText>
          </ul>
          <TermsText>
            Data we collect can depend on the individual settings of your device
            and software. We recommend checking the policies of your device
            manufacturer or software provider to learn what information they
            make available to us.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Personal Information</H2>
          <TermsText>
            We may ask for personal information — when you contact us — which
            may include one or more of the following:
          </TermsText>
          <ul>
            <TermsText as="li">Name</TermsText>
            <TermsText as="li">Email</TermsText>
            <TermsText as="li">Phone/mobile number</TermsText>
          </ul>
        </section>
        <section>
          <H2 transform="uppercase">
            Legitimate Reasons for Processing Your Personal Information
          </H2>
          <TermsText>
            We only collect and use your personal information when we have a
            legitimate reason for doing so. In which instance, we only collect
            personal information that is reasonably necessary to provide our
            services to you.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Collection and Use of Information</H2>
          <TermsText>
            We may collect personal information from you when you do any of the
            following on our website:
          </TermsText>
          <ul>
            <TermsText as="li">
              Use a mobile device or web browser to access our content
            </TermsText>
            <TermsText as="li">
              Contact us via email, social media, or on any similar technologies
            </TermsText>
            <TermsText as="li">When you mention us on social media</TermsText>
          </ul>
          <TermsText>
            We may combine voluntarily provided and automatically collected
            personal information with general information or research data we
            receive from other trusted sources. For example, Our marketing and
            market research activities may uncover data and insights, which we
            may combine with information about how visitors use our site to
            improve our site and your experience on it.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Security of Your Personal Information</H2>
          <TermsText>
            When we collect and process personal information, and while we
            retain this information, we will protect it within commercially
            acceptable means to prevent loss and theft, as well as unauthorized
            access, disclosure, copying, use, or modification.
          </TermsText>
          <TermsText>
            Although we will do our best to protect the personal information you
            provide to us, we advise that no method of electronic transmission
            or storage is 100% secure, and no one can guarantee absolute data
            security.
          </TermsText>
          <TermsText>
            You are responsible for selecting any password and its overall
            security strength, ensuring the security of your own information
            within the bounds of our services. For example, ensuring you do not
            make your personal information publicly available via our platform.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">
            How Long We Keep Your Personal Information
          </H2>
          <TermsText>
            We keep your personal information only for as long as we need to.
            This time period may depend on what we are using your information
            for, in accordance with this privacy policy. For example, if you
            have provided us with personal information such as an email address
            when contacting us about a specific enquiry, we may retain this
            information for the duration of your enquiry remaining open as well
            as for our own records so we may effectively address similar
            enquiries in future. If your personal information is no longer
            required for this purpose, we will delete it or make it anonymous by
            removing all details that identify you.
          </TermsText>
          <TermsText>
            However, if necessary, we may retain your personal information for
            our compliance with a legal, accounting, or reporting obligation or
            for archiving purposes in the public interest, scientific, or
            historical research purposes or statistical purposes.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Children’s Privacy</H2>
          <TermsText>
            We do not aim any of our products or services directly at children
            under the age of 13, and we do not knowingly collect personal
            information about children under 13.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">
            Your Rights and Controlling Your Personal Information
          </H2>
          <TermsText>
            <strong>Your choice:</strong> By providing personal information to
            us, you understand we will collect, hold, use, and disclose your
            personal information in accordance with this privacy policy. You do
            not have to provide personal information to us, however, if you do
            not, it may affect your use of our website or the products and/or
            services offered on or through it.
          </TermsText>
          <TermsText>
            <strong>Information from third parties:</strong> If we receive
            personal information about you from a third party, we will protect
            it as set out in this privacy policy. If you are a third party
            providing personal information about somebody else, you represent
            and warrant that you have such person’s consent to provide the
            personal information to us.
          </TermsText>
          <TermsText>
            <strong>Marketing permission:</strong> If you have previously agreed
            to us using your personal information for direct marketing purposes,
            you may change your mind at any time by contacting us using the
            details below.
          </TermsText>
          <TermsText>
            <strong>Access:</strong> You may request details of the personal
            information that we hold about you.
          </TermsText>
          <TermsText>
            <strong>Correction:</strong> If you believe that any information we
            hold about you is inaccurate, out of date, incomplete, irrelevant,
            or misleading, please contact us using the details provided in this
            privacy policy. We will take reasonable steps to correct any
            information found to be inaccurate, incomplete, misleading, or out
            of date.
          </TermsText>
          <TermsText>
            <strong>Non-discrimination:</strong> We will not discriminate
            against you for exercising any of your rights over your personal
            information. Unless your personal information is required to provide
            you with a particular service or offer (for example providing user
            support), we will not deny you goods or services and/or charge you
            different prices or rates for goods or services, including through
            granting discounts or other benefits, or imposing penalties, or
            provide you with a different level or quality of goods or services.
          </TermsText>
          <TermsText>
            <strong>Notification of data breaches:</strong> We will comply with
            laws applicable to us in respect of any data breach.
          </TermsText>
          <TermsText>
            <strong>Complaints:</strong> If you believe that we have breached a
            relevant data protection law and wish to make a complaint, please
            contact us using the details below and provide us with full details
            of the alleged breach. We will promptly investigate your complaint
            and respond to you, in writing, setting out the outcome of our
            investigation and the steps we will take to deal with your
            complaint. You also have the right to contact a regulatory body or
            data protection authority in relation to your complaint.
          </TermsText>
          <TermsText>
            <strong>Unsubscribe:</strong> To unsubscribe from our email database
            or opt-out of communications (including marketing communications),
            please contact us using the details provided in this privacy policy,
            or opt-out using the opt-out facilities provided in the
            communication. We may need to request specific information from you
            to help us confirm your identity.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Use of Cookies</H2>
          <TermsText>
            We use &ldquo;cookies&rdquo; to collect information about you and
            your activity across our site. A cookie is a small piece of data
            that our website stores on your computer, and accesses each time you
            visit, so we can understand how you use our site. This helps us
            serve you content based on preferences you have specified.
          </TermsText>
          <TermsText>
            Please refer to our Cookie Policy for more information.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Business Transfers</H2>
          <TermsText>
            If we or our assets are acquired, or in the unlikely event that we
            go out of business or enter bankruptcy, we would include data,
            including your personal information, among the assets transferred to
            any parties who acquire us. You acknowledge that such transfers may
            occur, and that any parties who acquire us may, to the extent
            permitted by applicable law, continue to use your personal
            information according to this policy, which they will be required to
            assume as it is the basis for any ownership or use rights we have
            over such information.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Limits of Our Policy</H2>
          <TermsText>
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            policies of those sites, and cannot accept responsibility or
            liability for their respective privacy practices.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Changes to This Policy</H2>
          <TermsText>
            At our discretion, we may change our privacy policy to reflect
            updates to our business processes, current acceptable practices, or
            legislative or regulatory changes. If we decide to change this
            privacy policy, we will post the changes here at the same link by
            which you are accessing this privacy policy.
          </TermsText>
          <TermsText>
            If required by law, we will get your permission or give you the
            opportunity to opt in to or opt out of, as applicable, any new uses
            of your personal information.
          </TermsText>
        </section>
        <section>
          <H2 transform="uppercase">Contact Us</H2>
          <TermsText>
            For any questions or concerns regarding your privacy, you may
            contact us using the following details:
          </TermsText>
          <TermsText>
            Luciano Nonnis
            <br />
            luciano.nonnis@greyapes.com
          </TermsText>
        </section>
      </Wrapper>
    </Layout>
  );
};
